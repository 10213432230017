import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './ReactHeader.css';

const ReactHeader = () => {
  const location = useLocation(); // Get the current route
  const isHomePage = location.pathname === '/events'; // Check if the current page is the homepage

  // Videos for the homepage
  const homepageVideos = [
    'assets/videos/Vibrant Dance Festival.mp4',
    'assets/videos/Rooftop Dance Vibes.mp4',
    'assets/videos/Inspiring Auditorium Address.mp4',
    'assets/videos/Saxophone Serenade.mp4',
    'assets/videos/Blend_Video.mp4',
  ];

  // Images for other pages
  const otherPageImages = [
    'images/artist0.png',
    'images/header-background-2.png',
    'images/header-wild_night_club.jpg',
    'images/header-concert-crowd.jpg',
  ];

  // Static images for small screens
  const smallScreenImages = ['images/general-vertical.png'];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 168);

  // Handle screen resize and item rotation
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 168);
    };

    window.addEventListener('resize', handleResize);

    const items = isHomePage ? homepageVideos : otherPageImages;
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 5000); // Change every 5 seconds

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(interval);
    };
  }, [isHomePage, homepageVideos.length, otherPageImages.length]);

  // Determine the current background item (video or image)
  const currentItem = isSmallScreen
    ? smallScreenImages[currentIndex % smallScreenImages.length]
    : isHomePage
    ? homepageVideos[currentIndex]
    : otherPageImages[currentIndex];

  // Check if text should be hidden based on the current item
  const noTextImages = ['images/ProArts.png'];
  const shouldShowText = !noTextImages.includes(currentItem);

  return (
    <main className="container-fluid p-0">
      <div
        className="p-1 p-md-5 text-light bg-body-secondary"
        style={{
          position: 'relative',
          height: isSmallScreen ? '400px' : '480px',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {/* Video or Image Background */}
        {isHomePage && !isSmallScreen ? (
          <video
            key={currentItem}
            src={currentItem}
            autoPlay
            muted
            loop
            playsInline
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Ensure the video fills the entire container
              pointerEvents: 'none', // Prevent video from blocking interactions
            }}
          ></video>
        ) : (
          <img
            src={currentItem}
            alt="Background"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Ensure the image fills the entire container
            }}
          />
        )}

        {/* Foreground Content */}
        <Container
          style={{
            position: 'relative', // Ensure content stays on top
            zIndex: '1', // Higher z-index than background
          }}
        >
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="px-2 my-4 text-left">
              {shouldShowText && (
                <>
                  <h1
                    className={`fw-bold fst-italic rounded text-shadow yeserva-font ${
                      isSmallScreen ? 'fs-3' : 'display-4'
                    }`}
                    style={{
                      fontSize: isSmallScreen ? '1.8rem' : undefined,
                      textAlign: isSmallScreen ? 'center' : undefined,
                      fontWeight: isSmallScreen ? '600' : undefined,
                      WebkitFontSmoothing: 'antialiased',
                      MozOsxFontSmoothing: 'grayscale',
                    }}
                  >
                    Host your Events With Us
                  </h1>
                  <h3
                    className={`text-shadow kavoon-font ${
                      isSmallScreen ? 'fs-5' : 'fs-2'
                    }`}
                    style={{
                      fontSize: isSmallScreen ? '1.2rem' : undefined,
                      textAlign: isSmallScreen ? 'center' : undefined,
                      WebkitFontSmoothing: 'antialiased',
                      MozOsxFontSmoothing: 'grayscale',
                    }}
                  >
                    <span className="top-text">Top Events,</span>
                    <span className="tickets-text">Top Tickets,</span>
                    <span className="with-text"> with </span>
                    <span className="eventtop-text">EventTop!</span>
                  </h3>
                </>
              )}
            </Col>

            {!isSmallScreen && (
              <div className="image-selectors p-1">
                {(isHomePage ? homepageVideos : otherPageImages).map((_, index) => (
                  <button
                    key={index}
                    className={`image-selector ${index === currentIndex ? 'active' : ''}`}
                    onClick={() => setCurrentIndex(index)}
                  ></button>
                ))}
              </div>
            )}

            <Link to="/EventHostingPage">
              <div className="text-start">
                <Button
                  variant="btn btn-primary fst-italic"
                  className="mt-5"
                  style={{
                    height: isSmallScreen ? '45px' : '50px',
                    width: isSmallScreen ? '180px' : undefined,
                    fontSize: isSmallScreen ? '0.8rem' : undefined,
                    padding: isSmallScreen ? '5px' : '10px',
                    whiteSpace: 'nowrap',
                    border: '3px solid black',
                    boxShadow: '2px 2px 8px rgba(0, 0, 1, 0.9)',
                    textAlign: 'center',
                  }}
                >
                  Organize Your Event
                </Button>
              </div>
            </Link>
          </Row>
        </Container>
      </div>
    </main>
  );
};

export default ReactHeader;