import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TicketStorePage from './TicketStorePage'; // Import the TicketStorePage component
import { API_ENDPOINTS } from '../config';

const TicketStore = () => {
  const [event, setEvent] = useState(null);
  const [tickets, setTickets] = useState([]); // Add tickets state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventID = searchParams.get('eventID');
  
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        if (!eventID) {
          setError('No event ID provided');
          setLoading(false);
          return;
        }
        
        // Fetch event details
        const eventResponse = await fetch(API_ENDPOINTS.getEvent(eventID));
        if (!eventResponse.ok) {
          throw new Error('Failed to fetch event details');
        }
        const eventData = await eventResponse.json();
        setEvent(eventData);

        // Attempt to fetch ticket data
        try {
          const ticketsResponse = await fetch(API_ENDPOINTS.getEventTickets(eventID));
          if (!ticketsResponse.ok) {
            throw new Error('Failed to fetch ticket data');
          }
          const ticketsData = await ticketsResponse.json();
          setTickets(ticketsData.tickets); // Store the tickets in state
        } catch (ticketError) {
          console.warn('No tickets found or error fetching tickets:', ticketError);
          setTickets([]); // Default to an empty array if tickets cannot be fetched
        }
      } catch (error) {
        console.error('Error fetching event data:', error);
        setError('Error fetching event data');
      } finally {
        setLoading(false);
      }
    };
    
    fetchEventData();
  }, [eventID]);
  
  if (loading) {
    return <div>Loading event details...</div>;
  }
  
  if (error) {
    return <div>{error}</div>;
  }
  
  // Render the TicketStorePage component and pass the event and ticket data to it
  return <TicketStorePage event={event} tickets={tickets} />;
};

export default TicketStore;