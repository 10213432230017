import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';

const TermsOfUse = () => {
  return (
    <Container className="py-4">
      <Row>
        <Col>
          <h1 className="mb-4">EventTop.ca Terms and Policies</h1>
          
    <p className="mb-4 text-muted">Last updated: Jan. 1, 2025</p>
          
    <p>
    This Terms of Use Agreement ("Agreement") is a legally binding contract between you ("User" or "you") and <a href="https://eventtop.ca" target="_blank" rel="noopener noreferrer">https://eventtop.ca</a> (the "Site") governing your use of the ticketing services provided through our website ("Services"). 
    EventTop.ca is a platform that facilitates event ticketing, management, and attendee communication. We provide tools for organizers to create, manage, and sell tickets for their events and for attendees to purchase tickets conveniently online.
    By accessing or using our Services, you agree to be bound by the terms and conditions of this Agreement. If you do not agree with these terms, please do not use our Services.
    </p>
    
    <p>
    <b>EventTop.ca</b> values your privacy and is committed to providing transparency about the information we collect, how it is used, and how you can control the collection, use, and sharing of your personal information (“Personal Information”), as outlined in this Privacy Policy. By accessing or using our Site or Services, you confirm that you have read, understood, and agreed to the terms of the service and Privacy Policy offered by EventTop.
    </p>
    <p>
    We encourage you to review the terms of use carefully to understand our practices and your options.
    </p>
          
          
        </Col>
      </Row>

      {/* Navigation Links */}
      <Row className="mb-5">
        <Col>
          <h2>Quick Links</h2>
          <ListGroup variant="flush">
            <ListGroup.Item><a href="#terms-of-service">EventTop.ca Terms of Service</a></ListGroup.Item>
            <ListGroup.Item><a href="#ads-terms">EventTop.ca Ads Terms and Conditions</a></ListGroup.Item>
            <ListGroup.Item><a href="#external-agreement">Can EventTop.ca sign an agreement outside of the Terms of Service?</a></ListGroup.Item>
            <ListGroup.Item><a href="#api-terms">API Terms of Use</a></ListGroup.Item>
            <ListGroup.Item><a href="#solutions-addendum">Solutions Addendum</a></ListGroup.Item>
            <ListGroup.Item><a href="#federal-agencies">Amendment to Terms of Service for Federal Agencies</a></ListGroup.Item>
            <ListGroup.Item><a href="#organizer-terms">Organizer Terms</a></ListGroup.Item>
            <ListGroup.Item><a href="#trademark-policy">Trademark & Copyright Policy</a></ListGroup.Item>
            <ListGroup.Item><a href="#refund-policy">Organizer Refund Policy Requirements</a></ListGroup.Item>
            <ListGroup.Item><a href="#chargeback-policy">Chargeback Policy</a></ListGroup.Item>
            <ListGroup.Item><a href="#non-events-policy">Policy on Non-Events</a></ListGroup.Item>
            <ListGroup.Item><a href="#raffles-policy">Raffles and Giveaways Policy</a></ListGroup.Item>
            <ListGroup.Item><a href="#cancelled-event-policy">Cancelled Event Policy</a></ListGroup.Item>
            <ListGroup.Item><a href="#postponed-event-policy">Postponed Event Policy</a></ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      {/* Terms Sections */}
      {/* Terms of Service Section */}
      <Row id="terms-of-service" className="mb-5">
      <Col>
      <h2>Terms of Service</h2>
      {/* Indented Content */}
      <div className="ms-4"> {/* Bootstrap class for left margin */}
      
        <h6>Changes to these Terms of Use</h6>
        <p>
        The Site reserves the right to make changes to these Terms of Use in whole or in part, at any time,
        at its sole discretion and without prior notice. The Site reserves the right to remove any information
        that you post, upload, publish, transmit, distribute, or otherwise use on the Site at any time, at its sole discretion.
        </p>
        
        <h6>Acceptance of Terms</h6>
        <p>
        By using our Services, you acknowledge that you have read, understood, and agree to be bound by this
        Agreement and any additional terms and policies referenced herein.
        </p>
        
        <h6>Restrictions on Commercial Use</h6>
        <p>
        The contents featured on this website, encompassing text, information, designs, photographs, graphics,
        images, illustrations, interfaces, codes, audio clips, video clips, software, and links to external websites (collectively referred to as the "Content"),
        are exclusively intended for informational purposes. The utilization of this Content for any commercial purposes is strictly prohibited.
        </p>
        
        <h6>Eligibility</h6>
        <p>
        You must be at least 18 years old to use our Services. By using our Services, you represent and warrant
        that you are at least 18 years old.
        </p>
        
        <h6>Account Registration</h6>
        <p>
        To access certain features of our Services, you may be required to register for an account. You agree to
        provide accurate, current, and complete information during the registration process and to update such information to keep it accurate.
        </p>
        
        <h6>Ticket Purchases</h6>
        <ListGroup variant="flush">
        <ListGroup.Item>
        <b>a. Payment:</b> When purchasing tickets through our Services, you agree to provide accurate and complete payment information.
        You authorize us to charge your payment method for the total amount of your purchase, including any applicable fees.
        </ListGroup.Item>
        <ListGroup.Item>
        <b>b. Refunds and Exchanges:</b> All ticket sales are final, and we do not offer refunds or exchanges unless otherwise specified. 
        In the event of a cancellation or rescheduling of an event by the organizer, we will provide information on our website regarding the refund or exchange process.
        </ListGroup.Item>
        </ListGroup>
        
        <h6>User Conduct</h6>
        <p>
        You agree not to use our Services for any unlawful or prohibited purpose and to comply with all applicable laws and regulations.
        </p>
        <ListGroup variant="flush">
        <ListGroup.Item>a. Violate any intellectual property rights.</ListGroup.Item>
        <ListGroup.Item>b. Interfere with or disrupt the Services.</ListGroup.Item>
        <ListGroup.Item>c. Engage in any fraudulent or deceptive activities.</ListGroup.Item>
        <ListGroup.Item>d. Use automated scripts to collect information from our website.</ListGroup.Item>
        </ListGroup>
        
        <h6>Intellectual Property</h6>
        <p>
        All content and materials available on our website, including but not limited to text, graphics, logos, button icons, images, audio clips,
        and software, are the property of the Company or its licensors and are protected by intellectual property laws.
        </p>
        
        <h6>Privacy Policy</h6>
        <p>
        Your use of our Services is subject to our <b>Privacy Policy</b>, which is hereby incorporated into this Agreement by reference. To understand our data collection, usage, and protection practices, please review the Privacy Policy available at 
        <a href="https://eventtop.ca/PrivacyPolicy" target="_blank" rel="noopener noreferrer">https://eventtop.ca/PrivacyPolicy</a>.
        </p>
        
        <h6>Limitation of Liability</h6>
        <p>
        To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss
        of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.
        </p>
        
        <h6>Termination</h6>
        <p>
        We reserve the right to terminate or suspend your account and access to the Services, without prior notice, for any reason, including a breach of this Agreement.
        </p>
        
      </div>
      </Col>
      </Row>
  
      {/* Ads Terms Section */}
      <Row id="ads-terms" className="mb-5">
        <Col>
          <h2>EventTop.ca Ads Terms and Conditions</h2>
          <p>
            These terms govern advertising on EventTop.ca. Advertisers must ensure their content complies with all applicable laws and regulations. 
            Ads containing offensive, misleading, or prohibited content will be removed without prior notice. Payments for ads are non-refundable unless 
            otherwise stated.
          </p>
        </Col>
      </Row>
      
      {/* External Agreements Section */}
      <Row id="external-agreement" className="mb-5">
      <Col>
      <h2>Can EventTop.ca sign an agreement outside of the Terms of Service?</h2>
      <p>
      In certain circumstances, EventTop.ca may enter into customized agreements with organizations. Such agreements must be approved by 
      EventTop.ca’s legal team and adhere to applicable laws and policies. Please contact support for inquiries.
      </p>
      </Col>
      </Row>
      
      {/* API Terms Section */}
      <Row id="api-terms" className="mb-5">
      <Col>
      <h2>API Terms of Use</h2>
      <p>
      EventTop.ca provides API access for developers to integrate with our platform. Usage of the API must comply with rate limits, data 
      protection regulations, and intellectual property rights. Misuse of the API will result in revocation of access.
      </p>
      </Col>
      </Row>
      
      {/* Solutions Addendum Section */}
      <Row id="solutions-addendum" className="mb-5">
      <Col>
      <h2>Solutions Addendum</h2>
      <p>
      This addendum applies to organizations using EventTop.ca for custom solutions. Additional fees, support terms, and service level 
      agreements may apply as outlined in the respective contracts.
      </p>
      </Col>
      </Row>
      
      {/* Federal Agencies Section */}
      <Row id="federal-agencies" className="mb-5">
      <Col>
      <h2>Amendment to Terms of Service for Federal Agencies</h2>
      <p>
      Special provisions apply to U.S. federal agencies using EventTop.ca, including compliance with federal acquisition regulations 
      and other applicable laws. Please consult with our team for agency-specific agreements.
      </p>
      </Col>
      </Row>
      
      {/* Organizer Terms Section */}
      <Row id="organizer-terms" className="mb-5">
      <Col>
      <h2>Organizer Terms</h2>
      <p>
      Organizers using EventTop.ca must comply with platform policies, including proper event listing, ticket refund management, and 
      communication with attendees. Violations may result in account suspension or termination.
      </p>
      </Col>
      </Row>



      <Row id="refund-policy" className="mb-5">
        <Col>
        <h2>Organizer Refund Policy Requirements</h2>
        {/* Indented Content */}
        <div className="ms-4">
        <p>
        EventTop.ca is committed to ensuring a fair and transparent refund process for canceled or rescheduled events. 
        Below are the requirements and guidelines for organizers to manage refunds effectively.
        </p>
        
        <h6>1. Refund Obligations</h6>
        <div className="ms-4">
          <p>
          Organizers are required to refund all tickets in the event of cancellation or rescheduling unless otherwise specified 
          and agreed upon by EventTop.ca. The refund process must adhere to the following rules:
          </p>
          <ul>
          <li>
          Refunds must be processed in full, including the ticket price and any applicable fees, unless specified in 
          the Terms of Service or event-specific policies.
          </li>
          <li>
          Refunds must be initiated within 5 business days of the event cancellation or rescheduling notice.
          </li>
          <li>
          Communication about refunds must be clear, timely, and made directly to attendees using the EventTop.ca 
          notification tools.
          </li>
          </ul>
        </div>
    
        <h6>2. Communication with Attendees</h6>
        <div className="ms-4">
          <p>
          Organizers must promptly notify attendees of any event cancellations or rescheduling. The notification must 
          include:
          </p>
          <ul>
          <li>The reason for the cancellation or rescheduling.</li>
          <li>Instructions on how attendees can claim refunds.</li>
          <li>Contact information for further inquiries.</li>
          </ul>
        </div>
        
        <h6>3. Service and Processing Fees</h6>
        <p>
        Depending on the event-specific agreement, the following rules apply to fees during refunds:
        </p>
        <ul>
        <li>
        <strong>Service Fees:</strong> EventTop.ca service fees are typically non-refundable unless stated otherwise. 
        Organizers are responsible for covering these fees if refunds are issued.
        </li>
        <li>
        <strong>Payment Processing Fees:</strong> Payment processors, such as Stripe or PayPal, may retain their fees. 
        Organizers are responsible for reimbursing attendees for the full ticket price, including fees.
        </li>
        </ul>
        
    <h6>4. Refund Disputes</h6>
    {/* Indented Content */}
    <div className="ms-4">
      <p>
      In cases where disputes arise regarding refunds, <b>EventTop.ca</b> reserves the right to intervene and issue refunds directly to attendees. Any refund amounts will be deducted from the organizer's account or future payouts. Organizers are required to cooperate fully with EventTop.ca to resolve disputes promptly and efficiently.
</p>
<p>
  The organizer assumes full responsibility for notifying ticket holders and managing all associated refunds. <b>EventTop.ca</b> offers attendee notification services for an additional fee of $100 per 200 attendees. EventTop’s role is limited to facilitating ticket sales and payouts for completed transactions and is not responsible for managing refunds or disputes unless explicitly agreed upon in advance.
</p>
<p>
  Organizers must ensure that their refund policies are transparent, communicated to attendees, and aligned with <b>EventTop.ca’s</b> guidelines. In the event of a cancellation, postponement, or force majeure, <b>EventTop.ca</b> reserves the right to determine the appropriate refund policy and process refunds accordingly, with limited liability for any losses incurred.
</p>
<p>
  <b>EventTop.ca</b> reserves the right to verify and reconcile sales records prior to any additional payouts. Any discrepancies or disputes regarding sales records must be raised within <b>2 days</b> of receiving the sales summary. After this period, the provided ticket sales summary will be considered final and binding.
</p>
<p>
  If an organizer fails to respond or cooperate during a refund dispute, <b>EventTop.ca</b> may take independent action to resolve the issue in the best interest of attendees. This may include issuing refunds directly or reporting unresolved disputes to relevant authorities.
</p>
<p>
  In cases where attendees initiate chargebacks or disputes with their payment processor, the organizer is financially responsible for covering any chargeback amounts and associated fees. <b>EventTop.ca</b> will notify the organizer of such incidents and deduct the relevant amounts from their account.
</p>
<p>
  Refunds processed by <b>EventTop.ca</b> will follow a standard timeline, typically within <b>7-10 business days</b> of dispute resolution. Delays caused by the organizer’s actions or lack of response may extend this timeline.
</p>
<p>
  <b>EventTop.ca</b> is not liable for disruptions, delays, or cancellations caused by unforeseen events, including but not limited to natural disasters, pandemics, government regulations, or technical failures. In such cases, <b>EventTop.ca</b> will work with the organizer in good faith but cannot guarantee resolution within standard timelines.
</p>
    </div>

  
          <h6>5. Best Practices for Organizers</h6>
    <div className="ms-4">

          <p>
            To ensure smooth refund management, organizers are encouraged to:
          </p>
            <ul>
              <li>Set clear refund policies at the time of ticket listing.</li>
              <li>Maintain transparent communication with attendees throughout the process.</li>
              <li>Use EventTop.ca tools to automate and track refunds.</li>
            </ul>
</div>
          </div>
        </Col>
      </Row>

      <Row id="chargeback-policy" className="mb-5">
        <Col>



<h2>Chargeback Policy</h2>
  <div className="ms-4">

    <p>
      A chargeback occurs when an attendee disputes a transaction through their bank or payment processor, resulting in the reversal of the payment. Common reasons for chargebacks include unauthorized transactions, dissatisfaction with services, or non-delivery of the event.
    </p>
    <h5>Roles and Responsibilities</h5>
    <p>
      Organizers are responsible for any chargebacks related to ticket sales for their events. EventTop will notify the organizer of the chargeback and assist with gathering evidence to dispute the chargeback if applicable. The organizer assumes full financial responsibility for the chargeback amount and any associated fees.
    </p>
    <h5>Chargeback Fees</h5>
    <p>
      Each chargeback incurs a fee of $25, which covers administrative costs and fees charged by the payment processor. This fee, along with the disputed transaction amount, will be deducted from the organizer’s account or future payouts.
    </p>
    <h5>Dispute Resolution Process</h5>
    <p>
      Upon notification of a chargeback, organizers will have 7 calendar days to provide supporting evidence to dispute the chargeback. Evidence may include proof of ticket purchase, attendee communication logs, or documentation proving the event occurred as advertised. EventTop will submit this information to the payment processor on behalf of the organizer. The final decision regarding the chargeback will be made by the payment processor or the attendee’s bank.
    </p>
    <h5>Chargeback Limits</h5>
    <p>
      Accounts with a chargeback rate exceeding 1% of transactions in a 90-day period may be subject to review, suspension, or termination. EventTop reserves the right to withhold payouts or require additional reserves for accounts with excessive chargebacks.
    </p>
    <h5>Force Majeure</h5>
    <p>
      EventTop is not liable for chargebacks resulting from event cancellations due to unforeseen circumstances, including but not limited to natural disasters, pandemics, government restrictions, or technical failures. Organizers are encouraged to maintain clear refund and cancellation policies to minimize disputes.
    </p>

  </div>


        </Col>
      </Row>

{/* Cancelled Event Policy Section */}
<Row id="cancelled-event-policy" className="mb-5">
  <Col>
    <h2>Cancelled Event Policy</h2>
    <div className="ms-4">
      <p>
        EventTop.ca provides clear guidelines to manage event cancellations, ensuring transparency and fairness for both organizers and attendees. Below are the steps and obligations that organizers must follow in the event of a cancellation:
      </p>

      <p>
        Organizers canceling an event will be charged an administrative fee of <b>12% of the total refund amount</b>, which will be deducted from their account or future payouts. This fee covers the costs of processing refunds and managing attendee communications. Cancellations caused by force majeure events may be exempt from this fee at EventTop.ca’s discretion.
      </p>

      <h6>1. Notify EventTop.ca Support</h6>
      <p>
        Organizers must notify EventTop.ca support immediately upon deciding to cancel an event. The notification should include:
      </p>
      <ul>
        <li>The event name and scheduled date.</li>
        <li>The reason for cancellation.</li>
        <li>Any additional details required for processing refunds.</li>
      </ul>
      <p>
        Delayed notifications may result in additional penalties or administrative fees, as attendees expect timely updates and refunds.
      </p>

      <h6>2. Refund Requirements</h6>
      <div className="ms-4">
        <p>
          EventTop.ca will work with organizers to facilitate refunds for all purchased tickets. The refund process must adhere to these rules:
        </p>
        <ul>
          <li>Refunds must include the ticket price and any applicable service fees unless otherwise stated.</li>
          <li>Refunds must be processed within <b>5 business days</b> of the cancellation announcement.</li>
          <li>Organizers are responsible for any processing fees not covered by EventTop.ca’s policies.</li>
          <li>Refunds for rescheduled events or partial cancellations must be communicated clearly to attendees.</li>
          <li>For ticket insurance or non-refundable tickets, attendees should refer to the terms provided at the time of purchase.</li>
        </ul>
      </div>

      <h6>3. Communication with Attendees</h6>
      <div className="ms-4">
        <p>
          EventTop.ca will notify attendees about the cancellation and provide details about refunds or any alternative solutions. Organizers must cooperate fully with EventTop.ca to ensure attendees are informed promptly. Organizers may also opt to use EventTop.ca’s attendee notification services at an additional fee of $100 per 200 attendees.
        </p>
      </div>

      <h6>4. Force Majeure and Unforeseen Events</h6>
      <p>
        Event cancellations caused by natural disasters, government restrictions, pandemics, or other force majeure events will be handled on a case-by-case basis. EventTop.ca and the organizer are not liable for any losses incurred due to such events, but both parties will work together in good faith to provide refunds or alternative solutions to attendees.
      </p>

      <h6>5. Chargebacks and Financial Responsibilities</h6>
      <ul>
        <li>Attendees initiating chargebacks may result in additional fees, which the organizer is responsible for covering.</li>
        <li>EventTop.ca reserves the right to deduct refund amounts, chargebacks, and associated fees from the organizer’s account or future payouts.</li>
        <li>Organizers are encouraged to communicate refund policies clearly to minimize disputes and chargebacks.</li>
      </ul>

      <h6>6. Best Practices for Organizers</h6>
      <ul>
        <li>Inform EventTop.ca support as early as possible to minimize disruptions.</li>
        <li>Be transparent about the reasons for cancellation to maintain attendee trust.</li>
        <li>Follow EventTop.ca’s guidance to streamline refund and communication processes.</li>
        <li>Maintain a contingency fund to cover refund liabilities in case of cancellation.</li>
      </ul>
    </div>
  </Col>
</Row>

{/* Postponed Event Policy Section */}
<Row id="postponed-event-policy" className="mb-5">
  <Col>
    <h2>Postponed Event Policy</h2>
    <div className="ms-4">
      <p>
        Postponing an event requires clear communication and coordination to ensure transparency and minimize disruptions for attendees. 
        <b>EventTop.ca</b> provides detailed guidelines to manage rescheduling and maintain attendee trust. Organizers must adhere to the following policies:
      </p>

      <h6>1. Notify EventTop.ca Support</h6>
      <p>
        Organizers must inform <b>EventTop.ca</b> support immediately upon deciding to postpone an event. The notification must include:
      </p>
      <ul>
        <li>The event name and original date.</li>
        <li>The reason for the postponement.</li>
        <li>Details about the new date and time (if available).</li>
      </ul>
      <p>
        Notifications must be provided at least 72 hours prior to the original event date whenever possible to allow adequate time for communication with attendees.
      </p>

      <h6>2. Limitations on Postponement</h6>
      <p>
        Events may only be postponed <b>once</b>. If an organizer is unable to host the event on the rescheduled date, the event will be considered canceled, and the <b>Cancelled Event Policy</b> will apply.
      </p>
      <p>
        Organizers must provide the new event date within <b>14 calendar days</b> of the postponement notification. If no date is provided within this timeframe, EventTop.ca reserves the right to treat the event as canceled and initiate refunds.
      </p>

      <h6>3. Rescheduling Options</h6>
      <p>
        If the new event date is not immediately available, organizers must:
      </p>
      <ul>
        <li>Work with <b>EventTop.ca</b> to establish a timeline for announcing the new date.</li>
        <li>Provide regular updates to EventTop.ca support to ensure attendees are kept informed.</li>
        <li>Confirm the new date with EventTop.ca support at least 30 days prior to the rescheduled event.</li>
      </ul>

      <h6>4. Refund Policy for Postponed Events</h6>
      <p>
        Attendees who are unable or unwilling to attend the rescheduled event must be offered a refund. <b>EventTop.ca</b> will facilitate this process, subject to the following conditions:
      </p>
      <ul>
        <li>Refunds must include the full ticket price and any applicable service fees unless otherwise stated in the event’s refund policy.</li>
        <li>Refund requests must be processed within <b>5 business days</b> of receipt.</li>
        <li>Organizers are responsible for covering all associated refund fees unless otherwise agreed with EventTop.ca.</li>
      </ul>

      <h6>5. Communication with Attendees</h6>
      <p>
        <b>EventTop.ca</b> will notify attendees of the postponement and provide information on the new event date or refund options. Organizers must:
      </p>
      <ul>
        <li>Cooperate fully with EventTop.ca to ensure timely and accurate communication.</li>
        <li>Provide attendees with clear details about the rescheduled event or how to request refunds.</li>
        <li>Consider using EventTop.ca’s attendee notification services for an additional fee of <b>$100 per 200 attendees</b>.</li>
      </ul>

      <h6>6. Best Practices for Organizers</h6>
      <ul>
        <li>Communicate the postponement decision as early as possible to minimize attendee frustration.</li>
        <li>Offer incentives (e.g., discounts, complimentary items, or upgrades) to encourage attendees to retain their tickets for the rescheduled event.</li>
        <li>Ensure all updates and communications through <b>EventTop.ca</b> are professional, transparent, and timely to maintain attendee trust.</li>
      </ul>

      <h6>7. Force Majeure and Unforeseen Circumstances</h6>
      <p>
        If the postponement is due to unforeseen circumstances such as natural disasters, pandemics, or government restrictions, <b>EventTop.ca</b> will work with the organizer in good faith to reschedule the event or manage refunds. Force majeure events do not exempt the organizer from refund obligations for attendees who cannot attend the rescheduled event.
      </p>

      <h6>8. Failure to Adhere to Policy</h6>
      <p>
        Failure to adhere to these postponement guidelines may result in penalties, including administrative fees, suspension of the organizer’s account, or withholding of future payouts. EventTop.ca reserves the right to enforce these measures to ensure fairness and maintain platform integrity.
      </p>
    </div>
  </Col>
</Row>

    </Container>
  );
};

export default TermsOfUse;