import React from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { FaTicketAlt, FaUtensils, FaBriefcase, FaMusic, FaChalkboardTeacher, FaTools, FaMicrophone, FaImage, FaHandshake, FaUsers, FaHandsHelping, FaGift, FaAward, FaHeart, FaBirthdayCake, FaCalendarAlt, FaFutbol } from "react-icons/fa";

const EventTypeSelector = ({ formData, setFormData }) => {
    const eventTypes = [
        { label: "General Admission", value: "general admission", icon: <FaTicketAlt /> },
        { label: "Banquet Hall", value: "banquet hall", icon: <FaUtensils /> },
        { label: "Conference", value: "conference", icon: <FaBriefcase /> },
        { label: "Festival", value: "festival", icon: <FaMusic /> },
        { label: "Seminar", value: "seminar", icon: <FaChalkboardTeacher /> },
        { label: "Workshop", value: "workshop", icon: <FaTools /> },
        { label: "Concert", value: "concert", icon: <FaMicrophone /> },
        { label: "Exhibition", value: "exhibition", icon: <FaImage /> },
        { label: "Trade Show", value: "trade show", icon: <FaHandshake /> },
        { label: "Networking Event", value: "networking event", icon: <FaUsers /> },
        { label: "Charity Event", value: "charity event", icon: <FaHandsHelping /> },
        { label: "Product Launch", value: "product launch", icon: <FaGift /> },
        { label: "Award Ceremony", value: "award ceremony", icon: <FaAward /> },
        { label: "Wedding", value: "wedding", icon: <FaHeart /> },
        { label: "Birthday Party", value: "birthday party", icon: <FaBirthdayCake /> },
        { label: "Anniversary Celebration", value: "anniversary celebration", icon: <FaCalendarAlt /> },
        { label: "Fundraising Event", value: "fundraising event", icon: <FaHandsHelping /> },
        { label: "Sports Event", value: "sports event", icon: <FaFutbol /> },
    ];
    
    return (
        <div className="form-group mb-6">
            <label className="form-label fw-bold text-primary">Event Type:</label>
            
            {/* For Small Screens */}
            <div className="d-lg-none">
                <Dropdown>
                    <Dropdown.Toggle variant="outline-primary" className="w-100">
                        {formData.EventType
                            ? eventTypes.find((type) => type.value === formData.EventType)?.label
                            : "Select Event Type"}
                    </Dropdown.Toggle>
                    
                    <Dropdown.Menu>
                        {eventTypes.map((eventType) => (
                            <Dropdown.Item
                                key={eventType.value}
                                onClick={() =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        EventType: eventType.value,
                                    }))
                                }
                            >
                                <span style={{ marginRight: "10px", fontSize: "18px", color: "#007bff" }}>
                                    {eventType.icon}
                                </span>
                                {eventType.label}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            
            {/* For Medium and Large Screens */}
            <div className="d-none d-lg-block">
                <Row className="mt-2">
                    {eventTypes.map((eventType) => (
                        <Col lg={2} md={3} key={eventType.value} className="mb-3">
                            <div
                                className={`event-type-card ${
                                    formData.EventType === eventType.value ? "selected" : ""
                                }`}
                                onClick={() =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        EventType: eventType.value,
                                    }))
                                }
                                style={{
                                    cursor: "pointer",
                                    border: formData.EventType === eventType.value ? "2px solid #007bff" : "1px solid #ccc",
                                    borderRadius: "8px",
                                    height: "120px", // Fixed height for consistency
                                    textAlign: "center",
                                    boxShadow: formData.EventType === eventType.value
                                        ? "0 4px 8px rgba(0, 123, 255, 0.2)"
                                        : "0 2px 4px rgba(0, 0, 0, 0.1)",
                                    transition: "all 0.3s ease",
                                    backgroundColor: formData.EventType === eventType.value ? "#f0f8ff" : "#fff",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center", // Center content vertically
                                    alignItems: "center",
                                    gap: "5px",
                                    padding: "10px", // Minimal padding
                                }}
                            >
                                <div style={{ fontSize: "24px", color: "#007bff" }}>{eventType.icon}</div>
                                <span style={{ fontSize: "12px", fontWeight: "500" }}>{eventType.label}</span>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default EventTypeSelector;