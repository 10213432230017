import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const BanquetHallLayout = ({ tableOptions, handleSeatSelection }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentTable, setCurrentTable] = useState(null);
  const [totalSeats, setTotalSeats] = useState('');
  const [kidSeats, setKidSeats] = useState('');
  const [showSwipeHint, setShowSwipeHint] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
  
  
  // Always call the useEffect hook
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    
    // Show hint on small screens
    if (screenWidth < 800) {
      setShowSwipeHint(true);
      const timeout = setTimeout(() => setShowSwipeHint(false), 8000); // Hide after 3 seconds
      return () => clearTimeout(timeout);
    }
    
    return () => window.removeEventListener('resize', handleResize);
  }, [screenWidth]);
  
  // If tableOptions is missing, render the fallback directly in JSX
  if (!tableOptions || tableOptions.length === 0) {
    return <p>No tables available.</p>;
  }

  // Function to determine styles dynamically
  const determineStyles = (element) => {
    const isTable = element.ElementType === 'Table';
    const isSeat = element.ElementType === 'Seat';
    const isDanceFloor = element.ElementType === 'Dance Floor';
    const isStage = element.ElementType === 'Stage';
    const isBar = element.ElementType === 'Bar';
    const isCircle = element.ElementType === 'Circle';
    const isBackground = element.ElementType === 'Background';
    
    const baseColor = isBackground
    ? 'rgba(128, 128, 128, 0.5)' // Gray with 50% transparency for Background
    : element.AvailableSeats === 0
    ? '#ccc'
    : element.ElementColor ||
    (isTable
      ? element.TableType === 'VIP'
      ? '#ffd700' // Gold for VIP tables
      : '#007bff' // Blue for standard tables
      : isDanceFloor
      ? '#fffaad' // Light yellow for dance floor
      : isSeat
      ? '#28a745' // Green for seats
      : '#2d2d2d'); // Soft black for stage and bar
    
    const containerStyles = {
      position: 'absolute',
      top: `${element.LocationY || 0}%`,
      left: `${element.LocationX || 0}%`,
      width: isSeat
      ? 'clamp(10px, 3vw, 20px)'
      : isTable
      ? 'clamp(20px, 8vw, 40px)'
      : `${element.Width || 10}%`,
      height: isSeat
      ? 'clamp(10px, 3vw, 20px)'
      : isTable
      ? 'clamp(20px, 8vw, 40px)'
      : `${element.Height || 10}%`,
      borderRadius: isCircle
      ? '50%'
      : isTable
      ? '25%'
      : isSeat
      ? '0%'
      : '0%', // No rounded corners for default
      backgroundColor: baseColor,
      backgroundImage:
      isStage || isBar
      ? 'repeating-linear-gradient(45deg, #333333, #333333 15px, #444444 15px, #444444 30px)'
      : 'none', // Add stripes for stage and bar
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: isTable && element.AvailableSeats > 0 ? 'pointer' : 'not-allowed',
      color: isTable || isSeat || isCircle ? '#000' : '#ffffff',
      textAlign: 'center',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      fontSize: isSeat
      ? '0.6rem'
      : isCircle
      ? '0.6rem'
      : isTable
      ? 'clamp(0.6rem, 1.5vw, 0.8rem)'
      : '1.3rem',
      transform: isTable ? 'rotate(45deg)' : 'none',
      border: '1px solid black',
      zIndex: isBackground ? 0 : 10, // Background items are behind everything else
      pointerEvents: isBackground ? 'none' : 'auto', // Ignore pointer events on the background
    };
    
    const textStyles =
    isStage && element.Width < element.Height
    ? { transform: 'rotate(270deg)', whiteSpace: 'nowrap' }
    : { transform: 'none' };
    
    return { containerStyles, textStyles };
  };  

  const handleModalSubmit = () => {
    if (!currentTable) return;
    
    const { TableID, TableNumber, TableType, PricePerSeat, AvailableSeats, KidsDiscount, DiscountAmount } = currentTable;
    
    const totalSeatsNum = parseInt(totalSeats, 10);
    const kidSeatsNum = parseInt(kidSeats, 10) || 0; // Default kidSeats to 0 if not provided
    
    // Validation
    if (
      isNaN(totalSeatsNum) ||
      totalSeatsNum <= 0 ||
      totalSeatsNum > AvailableSeats ||
      (KidsDiscount && DiscountAmount > 0 && (
        isNaN(kidSeatsNum) || 
        kidSeatsNum < 0 || 
        kidSeatsNum > totalSeatsNum
      ))
    ) {
      alert('Invalid input. Please ensure your inputs are correct.');
      return;
    }
    
    // Calculate total price
    const adultSeats = totalSeatsNum - kidSeatsNum;
    const totalPrice = KidsDiscount && DiscountAmount > 0
    ? adultSeats * PricePerSeat + kidSeatsNum * (PricePerSeat - DiscountAmount)
    : totalSeatsNum * PricePerSeat; // No discount if KidsDiscount is false or DiscountAmount is 0
    
    // Call the parent selection handler
    handleSeatSelection({
      tableId: TableID,
      tableNumber: TableNumber,
      tableType: TableType,
      price: PricePerSeat,
      totalSeats: totalSeatsNum,
      kidSeats: KidsDiscount && DiscountAmount > 0 ? kidSeatsNum : 0, // Include kidSeats only if discount is enabled
      totalPrice,
    });
    
    // Reset modal
    setShowModal(false);
    setTotalSeats('');
    setKidSeats('');
  };

  return (
    <div
    style={{
      position: 'relative',
      width: '100%', // Parent container takes full width
      height: '65vh', // Parent container height
      overflow: 'auto', // Enable scrolling for the parent container if needed
      backgroundColor: '#f8f9fa',
      border: '2px solid #ccc',
      borderRadius: '10px',
      margin: '0 auto', // Centers the parent container
    }}
    >
    
    {/* Swipe Hint for Small Screens */}
    {showSwipeHint && (
      <div
      style={{
        position: 'absolute',
        top: '5%',
        left: '50%',
        transform: 'translateX(-50%)',
        background: '#007bff',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '8px',
        zIndex: 1000,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        pointerEvents: 'none',
      }}
      >
      Swipe left/right to view full map →
      </div>
    )}
    
    
    {/* Map Layout Div */}
    <div
    style={{
      position: 'relative',
      width: window.innerWidth < 800 ? '1100px' : '100%', // Adjust width based on screen size
      height: '100%', // Match the parent container height
      backgroundColor: '#fff', // Optional: Add a different background color for the map
      overflow: 'auto', // Enable scrolling if the map exceeds the parent container
    }}
    >
    {tableOptions.map((element) => {
      const styles = determineStyles(element);
      
      return (
        <div
        key={element.TableID || element.ElementID}
        style={styles.containerStyles}
        title={
          element.ElementType === 'Table'
          ? `Table: ${element.TableNumber}\nSeats Available: ${element.AvailableSeats}`
          : element.ElementType === 'Seat' || element.ElementType === 'Circle'
          ? `Seat Information\nAvailable: ${element.AvailableSeats}`
          : element.ElementType
        }
        onClick={() => {
          if (
            element.ElementType === 'Table' || 
            element.ElementType === 'Seat' || 
            element.ElementType === 'Circle'
          ) {
            // Check if the element is reserved
            if (element.IsReserved === 1) {
              alert(`${element.ElementType === 'Table' ? `Table ${element.TableNumber}` : 'Seat'} is reserved.`);
            } 
            // Check if the element has no available seats
            else if (element.AvailableSeats === 0) {
              alert(`${element.ElementType === 'Table' ? `Table ${element.TableNumber}` : 'Seat'} is not available for sale.`);
            } 
            // Open modal for available seats or tables
            else {
              setCurrentTable(element);
              setShowModal(true);
            }
          } 
          // Handle other element types
          else if (element.ElementType === 'Stage') {
            alert('This is the stage area. No actions available.');
          } else if (element.ElementType === 'Bar') {
            alert('This is the bar area. No actions available.');
          }
        }}
        >
        <div style={styles.textStyles}>
        {element.TableNumber || element.ElementType}
        </div>
        </div>
      );
    })}
    </div>
    
    {/* Modal */}
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
    <Modal.Header closeButton>
    <Modal.Title>Seat Selection</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {currentTable && (
      <>
      <p style={{ margin: 0 }}>
      <strong>Table:</strong> {currentTable.TableNumber}
      </p>
      <p style={{ margin: 0 }}>
      <strong>Price per seat:</strong> ${currentTable.PricePerSeat.toFixed(2)}
      </p>
      <p style={{ margin: 0 }}>
      <strong>Available seats:</strong> {currentTable.AvailableSeats}
      </p>
      {currentTable.DiscountAmount > 0 && (
        <div
        style={{
          margin: '10px 0',
          padding: '10px',
          border: '1px solid #007bff',
          backgroundColor: '#e7f3ff',
          color: '#004085',
          borderRadius: '4px',
        }}
        >
        <strong>Note:</strong> A discount of ${currentTable.DiscountAmount.toFixed(2)} will be applied to kid selections.
        </div>
      )}
      </>
    )}
    <Form>
    <Form.Group controlId="totalSeats" className="mb-3 mt-4">
    <Form.Label>Total Seats</Form.Label>
    <Form.Control
    type="number"
    value={totalSeats}
    onChange={(e) => setTotalSeats(e.target.value)}
    placeholder="Enter total seats"
    />
    </Form.Group>
    {currentTable && (currentTable.KidsDiscount || currentTable.DiscountAmount > 0) && (
      <Form.Group controlId="kidSeats" className="mb-3">
      <Form.Label>Kid Tickets</Form.Label>
      <Form.Control
      type="number"
      value={kidSeats}
      onChange={(e) => setKidSeats(e.target.value)}
      placeholder="Enter kid tickets"
      />
      </Form.Group>
    )}
    </Form>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
    Cancel
    </Button>
    <Button variant="primary" onClick={handleModalSubmit}>
    Confirm
    </Button>
    </Modal.Footer>
    </Modal>
    </div>
  );
};

export default BanquetHallLayout;