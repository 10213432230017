// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import RegistrationForm from './react-auth-app/RegistrationForm';
import SignInForm from './react-auth-app/SignInForm';
import ForgotPassword from "./react-auth-app/ForgotPassword";
import UserPanel from './react-auth-app/UserPanel';

import EventList from './event-showcase/EventList';
import TicketStorePage from './ticket-store/TicketStorePage';
import TicketStore from './ticket-store/TicketStore';
import EventInformation from './ticket-store/EventInformation';
import PurchaseConfirmation from './ticket-store/PurchaseConfirmation';
import CheckoutPage from './ticket-store/Checkout.js';
import OrderConfirmationPage from './ticket-store/OrderConfirmation.js';

import ReactNavbar from './react-navbar/navbar';
import ReactHeader from './react-header/header';
import AboutUsPage from './AboutUsPage';
import OnlinePaymentGuidePage from './OnlinePaymentGuide';
import ContactUsPage from './ContactUsPage';
import Careers from './CareersPage';
import TermsOfUse from './TermsOfUsePage';
import PrivacyPolicy from './PrivacyPolicyPage.js';
import Footer from './react-footer/react-footer'; 
import EventHostingPage from './Services/EventHostingPage.js'; 
import EventOrganizationInstructionsPage from './Services/EventOrganizationInstructions.js';
import AdvertisingInfo from './ads/AdvertisingInfo.js';

import TaxInformation from './TaxInformation.js'

import TicketSalesPage from './TicketSalesPage.js'; 
import { API_ENDPOINTS } from './config';

import ScrollToTop from './ScrollToTop';

import './index.css';

const App = () => {
  const [userData, setUserData] = useState(() => {
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    return accessToken && userId ? { accessToken, userId } : null;
  });
  
  useEffect(() => {
    if (userData && userData.accessToken) {
      fetchUserData(userData.accessToken, userData.userId);
    }
  }, [userData]);
  
  const fetchUserData = async (token, userId) => {
    try {
      const response = await fetch(API_ENDPOINTS.getUser(userId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const data = await response.json();
      setUserData({ ...userData, ...data });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  
  const handleSignOut = () => {
    setUserData(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    document.cookie = 'refreshToken=; Max-Age=0; path=/; domain=' + window.location.hostname;
    window.location.reload(); // Force a page reload to clear any lingering state
  };
    
  return (
    <Router>
    <ScrollToTop />
    
    <div className="container-fluid p-0">
    {/* Pass userData as user and handleSignOut to ReactNavbar */}
    <ReactNavbar user={userData} onSignOut={handleSignOut} />
    <ReactHeader user={userData} />
    <Routes>
    {/* Public routes */}
    <Route path="/registration-form" element={<RegistrationForm />} />
    <Route path="/sign-in" element={<SignInForm setUserData={setUserData} />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="/events" element={<EventList />} />
    
    {/* Ticket store routes */}
    <Route path="/ticket-store" element={<TicketStore />} />
    <Route path="/ticket-store/details" element={<TicketStorePage />} />
    <Route path="/purchase-tickets" element={<EventInformation />} />
    <Route path="/purchase-confirmation" element={<PurchaseConfirmation />} />
    <Route path="/checkout" element={<CheckoutPage />} />
    <Route path="/order-confirmation" element={<OrderConfirmationPage />} />
    
    {/* User-related routes */}
    <Route path="/userpanel" element={<UserPanel userData={userData} />} />
    
    {/* Static pages */}
    <Route path="/about-us" element={<AboutUsPage />} />
    <Route path="/contact-us" element={<ContactUsPage />} />
    <Route path="/careers" element={<Careers />} />
    <Route path="/TermsOfUse" element={<TermsOfUse />} />
    <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
    <Route path="/EventHostingPage" element={<EventHostingPage />} />
    <Route path="/event-organization-instructions" element={<EventOrganizationInstructionsPage />} />
    <Route path="/TicketSalesPage" element={<TicketSalesPage />} />
    <Route path="/OnlinePaymentGuide" element={<OnlinePaymentGuidePage />} />
    <Route path="/advertising-info" element={<AdvertisingInfo />} />
    <Route path="/tax-information" element={<TaxInformation />} />
    
    {/* Redirect to events as default */}
    <Route path="/" element={<Navigate to="/events" />} />
    
    {/* 404 Not Found */}
    <Route path="*" element={<div>404 Page Not Found</div>} />
    </Routes>
    <Footer />
    </div>
    </Router>
  );
};

export default App;
