// Checkout.js

import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { API_ENDPOINTS, mode } from '../config'; // Import mode from config

// Load Stripe key based on the environment
// Define the Stripe key based on the environment
const stripeKey = mode === 'development' 
? process.env.REACT_APP_STRIPE_KEY_TEST 
: process.env.REACT_APP_STRIPE_KEY_LIVE;
const stripePromise = loadStripe(stripeKey);

const CheckoutForm = ({ event, selectionType, selectedTickets, selectedSeats, totalWithFees, totalAmount }) => {

//const location = useLocation();
//const { event, selectionType, selectedTickets, totalWithFees, totalAmount } = location.state || {};


    //console.log('Debug - Location State:', location.state);
//  console.log('Debug - Event:', event);
//  console.log('Debug - Selection Type:', selectionType);
//  console.log('Debug - Selected Tickets:', selectedTickets);
//  console.log('Debug - totalAmount:', totalAmount);
//  console.log('Debug - Selected Seats:', selectedSeats);
//  console.log('Debug - Total with Fees:', totalWithFees);
//  console.log('Debug - Total Amount:', totalAmount);
//
//  console.log('Event:', event);
//  console.log('Selection Type:', selectionType);
//  console.log('Selected Tickets:', selectedTickets);
//  console.log('Selected Seats:', selectedSeats);
//  console.log('Total with Fees:', totalWithFees);
//  console.log('Total Amount:', totalAmount);
    
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [canMakePayment, setCanMakePayment] = useState(false);
    //const [timeLeft, setTimeLeft] = useState(0); // Timer state
    
    // Inside your CheckoutForm component
    const navigate = useNavigate();
    //const location = useLocation();
    //const { event, selectedTickets, totalWithFees } = location.state || {};

    const [buyerInfo, setBuyerInfo] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        subscribe: false,
    });

    // Handle input changes for buyer details
    const handleInputChange = (formField) => {
        const { name, value, checked, type } = formField.target;
        setBuyerInfo({
            ...buyerInfo,
            [name]: type === 'checkbox' ? checked : value,
        });
    };


    // Handle form submission
    const handleSubmit = async (incident) => {
        incident.preventDefault();
        setLoading(true);
        
        if (!stripe || !elements) {
            setError('Stripe has not loaded yet.');
            setLoading(false);
            return;
        }
        
        const cardElement = elements.getElement(CardElement);
        
        try {
            const amountInCents = totalWithFees * 100;
            const response = await fetch(`${API_ENDPOINTS.stripePayment}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ amount: amountInCents }),
            });
            
            const { clientSecret } = await response.json();

            const paymentResult = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: `${buyerInfo.firstName} ${buyerInfo.lastName}`,
                        email: buyerInfo.email,
                        phone: buyerInfo.phone,
                        address: {
                            line1: buyerInfo.address,
                            city: buyerInfo.city,
                            state: buyerInfo.state,
                            postal_code: buyerInfo.postalCode,
                            country: buyerInfo.country,
                        },
                    },
                },
            });
            
            if (paymentResult.error) {
                setError(paymentResult.error.message);
            } else if (paymentResult.paymentIntent.status === 'succeeded') {
                setPaymentSuccess(true);
                setError(null);
                
                // Extract the payment reference (paymentIntent.id)
                const paymentReference = paymentResult.paymentIntent.id;
                
                // console.log('selectionType in checkout',selectionType)
                
                // Show the success message for 2 seconds before navigating to the confirmation page
                setTimeout(() => {
                    navigate('/order-confirmation', {
                        state: {
                            event,
                            selectionType,
                            selectedTickets,
                            totalWithFees,
                            buyerInfo,
                            paymentReference, // Pass the payment reference to the next page
                        },
                    });
                }, 2000); // 2 seconds delay
            }
        } catch (error) {
            setError('Payment failed. Please try again.');
        }
        
        setLoading(false);
    };
    
    
    // Timer logic
    const initialEndTime = localStorage.getItem('endTime') 
    ? parseInt(localStorage.getItem('endTime'), 10) 
    : Date.now() + 20 * 60 * 1000; // 20 minutes from now
    const [endTime] = useState(initialEndTime);
    
    const [timeLeft, setTimeLeft] = useState(Math.max((endTime - Date.now()) / 1000, 0));
    
    useEffect(() => {
        localStorage.setItem('endTime', endTime); // Store endTime in localStorage
        
        const timer = setInterval(() => {
            const remainingTime = Math.max((endTime - Date.now()) / 1000, 0);
            setTimeLeft(remainingTime);
            if (remainingTime <= 0) {
                clearInterval(timer);
                navigate('/');
            }
        }, 1000);
        
        return () => clearInterval(timer);
    }, [endTime, navigate]);
    
    useEffect(() => {
        return () => {
            localStorage.removeItem('endTime'); // Clear the timer when the component unmounts
        };
    }, []);
    
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    
    // Convert the amount to cents and round to ensure it's an integer
    const totalAmountInCents = Math.floor(totalWithFees * 100); // Amount in cents
    //console.log('totalAmount',totalWithFees)
    //console.log('totalAmountInCents',totalAmountInCents)

    // Setup PaymentRequest API for Apple Pay/Google Pay
    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'CA',
                currency: 'cad',
                total: {
                    label: 'Total',
                    amount: totalAmountInCents, // Use the integer amount in cents
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            pr.canMakePayment().then((result) => {
                if (result) {
                    setPaymentRequest(pr);
                    setCanMakePayment(true);
                }
            });
        }
    }, [stripe, totalWithFees]);
    
    //console.log('${process.env.PUBLIC_URL}',`${process.env.PUBLIC_URL}`)

    return (
        <Container className="mt-4">
            <h3 className="text-center">Checkout</h3>

            <p className="text-danger text-center">
            <strong>Time left to complete your order: {formatTime(timeLeft)}</strong>
            </p>
            
            <div className="ticket-summary">
                <h5 className="text-left my-3 py-2 border-bottom bg-light">Order Summary</h5>

                    <div className="text mt-0"><strong>Event Name:</strong> {event.EventName}</div>
                    <div className="text mt-0"><strong>Event Date:</strong> {new Date(event.EventDate).toLocaleDateString('en-US')}</div>
                    <div className="text mt-0"><strong>Venue:</strong> {event.VenueName}, {event.VenueAddress}</div>

                <h5 className="text-left my-3 py-2 border-bottom bg-light">Selected Items</h5>
        
                <ul>
                {selectedTickets &&
                    Object.entries(selectedTickets).map(([ticketId, ticketDetails]) => (
                        <li key={ticketId}>
                        <strong>Type:</strong> {ticketDetails.ticketType} {ticketDetails.tableNumber} {ticketDetails.tableType} |
                        Quantity: {ticketDetails.quantity} | Price: ${ticketDetails.price} each 
                        </li>
                    ))}
                </ul>
                        
                <h5>Total: CA${totalWithFees.toFixed(2)}</h5>
            </div>

            <hr />
    
            {/* Payment Request Button for Apple Pay / Google Pay */}
            {/*
            {canMakePayment && paymentRequest && (
                <div className="mb-3">
                <Form.Group controlId="apple-google-pay">
                <Form.Label>Apple Pay / Google Pay</Form.Label>
                <PaymentRequestButtonElement
                options={{ paymentRequest }}
                className="form-control"
                />
                </Form.Group>
                </div>
            )}
            */}
                
        
            <hr className="my-4" /> {/* Horizontal line with spacing */}

            <p className="text-primary font-weight-bold mt-3">
            <i className="bi bi-exclamation-circle-fill me-2"></i>
            Customer Information:
            </p>
        
            {/* Credit Card Payment Form */}
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={buyerInfo.firstName}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastName"
                                value={buyerInfo.lastName}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                type="email"
                name="email"
                value={buyerInfo.email}
                onChange={(e) => {
                    const email = e.target.value;
                    handleInputChange({ target: { name: 'email', value: email } });
                    
                    // Simple validation for common typos
                    const commonMistakes = [
                        { wrong: '@gmial.com', correct: '@gmail.com' },
                        { wrong: '@yaho.com', correct: '@yahoo.com' },
                        { wrong: '@hotnail.com', correct: '@hotmail.com' },
                    ];
                    const suggestion = commonMistakes.find(mistake => email.includes(mistake.wrong));
                    if (suggestion) {
                        alert(`Did you mean "${email.replace(suggestion.wrong, suggestion.correct)}"?`);
                    }
                }}
                required
                placeholder="Enter your email"
                />
                <Form.Text className="text-muted">
                Please ensure your email address is valid and correctly formatted.
                </Form.Text>
                </Form.Group>

                <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                type="text"
                name="phone"
                value={buyerInfo.phone}
                onChange={(e) => {
                    // Automatically format the phone number as (555) 555-5555
                    let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                    if (value.length > 10) value = value.slice(0, 10); // Limit to 10 digits
                    
                    // Apply formatting: (XXX) XXX-XXXX
                    let formattedValue = value;
                    if (value.length > 6) {
                        formattedValue = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
                    } else if (value.length > 3) {
                        formattedValue = `(${value.slice(0, 3)}) ${value.slice(3)}`;
                    } else if (value.length > 0) {
                        formattedValue = `(${value}`;
                    }
                    
                    handleInputChange({ target: { name: 'phone', value: formattedValue } });
                }}
                inputMode="numeric" // Show numeric keypad on mobile
                placeholder="(555) 555-5555"
                maxLength="14" // Include space, parentheses, and dash
                required
                />
                <Form.Text className="text-muted">
                Enter a phone number in the format (555) 555-5555.
                </Form.Text>
                </Form.Group>
        
                <p className="text-danger font-weight-bold mt-3">
                <i className="bi bi-exclamation-circle-fill me-2"></i>
                All fields are mandatory.
                </p>

                <hr></hr>
                        
                <p className="text-primary font-weight-bold mt-3">
                <i className="bi bi-exclamation-circle-fill me-2"></i>
                Credit Card Information:
                </p>
        
                <div 
                className="d-flex mt-3 align-items-start bg-image mb-3" 
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/Visa-Mastercard.jpg)`,
                    backgroundSize: 'contain', // Use 'contain' to show the full image
                    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
                    backgroundPosition: 'left', // Align the image to the left
                    height: '50px', // Adjust the height as necessary
                    width: '100%', // Make the div full width within its container
                    maxWidth: '300px' // Limit the maximum width for responsiveness
                }}
                >
                </div>

                <p>Please provide your card details below:</p>
                <Form.Group controlId="card-element">
                    <Form.Label>Credit/Debit Card</Form.Label>
                    <CardElement
                        className="form-control"
                        options={{
                            hidePostalCode: false,
                            iconStyle: 'solid', // Use this to ensure card icons are solid
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                    iconColor: '#666EE8', // Customize icon color
                                },
                                invalid: {
                                    color: '#9e2146',
                                    iconColor: '#FF6347', // Customize icon color for invalid input
                                },
                            },
                        }}
                    />
                </Form.Group>
                
        <hr></hr>
        
        <p className="text-primary font-weight-bold mt-3">
            <i className="bi bi-exclamation-circle-fill me-2"></i>
            Billing Details:
        </p>
        
        <Form.Group controlId="address">
        <Form.Label>Street</Form.Label>
        <Form.Control
        type="text"
        name="address"
        value={buyerInfo.address}
        onChange={handleInputChange}
        required
        />
        </Form.Group>
        
        <Form.Group controlId="city">
        <Form.Label>City</Form.Label>
        <Form.Control
        type="text"
        name="city"
        value={buyerInfo.city}
        onChange={handleInputChange}
        required
        />
        </Form.Group>
        
        <Form.Group controlId="state">
        <Form.Label>State/Province</Form.Label>
        <Form.Control
        type="text"
        name="state"
        value={buyerInfo.state}
        onChange={handleInputChange}
        required
        />
        </Form.Group>
        
        <Form.Group controlId="postalCode">
        <Form.Label>Postal Code</Form.Label>
        <Form.Control
        type="text"
        name="postalCode"
        value={buyerInfo.postalCode}
        onChange={(e) => {
            const value = e.target.value.toUpperCase(); // Ensure uppercase for Canadian postal codes
            handleInputChange({ target: { name: 'postalCode', value } });
            
            // Validate postal code format
            const canadianRegex = /^[A-Z]\d[A-Z][ ]?\d[A-Z]\d$/; // Matches A1A 1A1 or A1A1A1
            const usRegex = /^\d{5}(-\d{4})?$/; // Matches 12345 or 12345-6789
            
            if (!canadianRegex.test(value) && !usRegex.test(value)) {
                e.target.setCustomValidity(
                    'Please enter a valid Canadian postal code (e.g., A1A 1A1) or US ZIP code (e.g., 12345 or 12345-6789).'
                );
            } else {
                e.target.setCustomValidity(''); // Clear error if valid
            }
        }}
        placeholder="e.g., A1A 1A1 or 12345"
        required
        />
        <Form.Text className="text-muted">
        Enter a valid Canadian postal code (e.g., A1A 1A1) or US ZIP code (e.g., 12345 or 12345-6789).
        </Form.Text>
        </Form.Group>
        
        <Form.Group controlId="country">
        <Form.Label>Country</Form.Label>
        <Form.Control
        as="select"
        name="country"
        value={buyerInfo.country}
        onChange={handleInputChange}
        required
        >
        <option value="">Select a Country</option>
        <option value="US">United States</option>
        <option value="CA">Canada</option>
        <option value="GB">United Kingdom</option>
        <option value="AU">Australia</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
        <option value="JP">Japan</option>
        <option value="IN">India</option>
        <option value="TR">Turkey</option>
        <option value="AZ">Azerbaijan</option>
        <option value="RU">Russia</option>
        <option value="CN">China</option>
        <option value="BR">Brazil</option>
        <option value="ZA">South Africa</option>
        {/* Add more countries as needed */}
        </Form.Control>
        </Form.Group>

        {error && <div className="text-danger mt-3">{error}</div>}

        <div className="d-flex align-items-center">
        <Button className="mt-4 me-3" variant="primary" type="submit" disabled={loading || !stripe}>
        {loading ? 'Processing...' : 'Pay Now'}
        </Button>
        <p className="text-danger mb-0 mt-4">
        Time left: {formatTime(timeLeft)}
        </p>
        </div>


                <p className="text-left p-3">
                    By clicking on Pay Now, I agree to the EventTop.ca&nbsp;
                    <a href="/TermsOfUse" className="font-weight-bold text-primary">
                        Terms of Service
                    </a>.
                </p>
            </Form>

            {paymentSuccess && (
                <div className="text-success mt-3">
                Payment Successful! Thank you for your purchase. You will be redirected to the confirmation page shortly.
                </div>
            )}
        
        </Container>
    );
};


const Checkout = () => {
    const location = useLocation();
    const { event, selectionType, selectedTickets, selectedSeats, totalWithFees } = location.state || {};
    
    const totalAmount = Object.values(
        selectionType === 'generalAdmission' ? selectedTickets : selectedSeats || {}
    ).reduce((acc, { price, quantity }) => acc + price * quantity, 0);
    
    return (
        <Elements stripe={stripePromise}>
        <CheckoutForm
        event={event}
        selectionType={selectionType}
        selectedTickets={selectedTickets}
        selectedSeats={selectedSeats}
        totalWithFees={totalWithFees}
        totalAmount={totalAmount}
        />
        </Elements>
    );
};

export default Checkout;