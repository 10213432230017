// src/config.js
// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3010';

const mode = process.env.APP_MODE || 'production'; // Default to 'development' if not set

// Utility function to get the appropriate environment variable based on mode
const getEnvVar = (baseKey) => {
  const isDevelopment = mode === 'development';
  const envVar = isDevelopment
  ? process.env[`${baseKey}_TEST`]
  : process.env[`${baseKey}_LIVE`];
  
  if (!envVar) {
    console.error(`Environment variable for ${baseKey} not found`);
  }
  
  return envVar;
};

// Load environment variables
const API_URL = getEnvVar('REACT_APP_API_URL');
const MAILSERVER_API_URL = getEnvVar('REACT_APP_MAIL_SERVER_API_URL');


//console.log('REACT_APP_API_URL_LIVE:', process.env.REACT_APP_API_URL_LIVE);
//console.log('REACT_APP_MAIL_SERVER_API_URL_LIVE:', process.env.REACT_APP_MAIL_SERVER_API_URL_LIVE);

//console.log(`Running in ${mode} mode` ); //? 'production' : 'development'} mode`);
//console.log('API_URL in use:', API_URL);  // 
//console.log('MAILSERVER_API_URL:', MAILSERVER_API_URL);




export const API_ENDPOINTS = {
  API_URL: `${API_URL}`,
  MAILSERVER_API_URL: `${MAILSERVER_API_URL}`,
  sendMail: `${MAILSERVER_API_URL}/send-email`,
  eventSearch: `${API_URL}/eventsearch`,
  getUser: (userId) => `${API_URL}/user/${userId}`,
  register: `${API_URL}/register`,
  login: `${API_URL}/login`,
  events: `${API_URL}/events`,
  getEvent: (eventID) => `${API_URL}/events/${eventID}`,
  PLACEHOLDER: '/uploads/placeholder_image.png',
  getTickets: (userId) => `${API_URL}/user/${userId}/tickets`,
  getEventTickets: (eventId) => `${API_URL}/events/${eventId}/tickets`,
  organizedEvents: (userId) => `${API_URL}/user/${userId}/organized-events`,
  uploads: `${API_URL}/uploads`,
  stripePayment: `${API_URL}/create-payment-intent`,
  saveCustomerInfo: `${API_URL}/save-customer-info`,
  reduceTicketAvailability: `${API_URL}/reduce-ticket-availability`,
  getEventPurchasedTickets: (eventId) => `${API_URL}/events/${eventId}/purchased-tickets`,
  createEvent: `${API_URL}/create-event`,
  updateEvent: (id) => `${API_URL}/events/${id}/update`, 
  deleteEvent: (eventId) => `${API_URL}/events/${eventId}`,
  getEventTables: (eventId) => `${API_URL}/events/${eventId}/tables`, // Endpoint for fetching table data  
  refreshToken: `${API_URL}/refresh-token`,
  getEventTables: (eventId) => `${API_URL}/events/${eventId}/tables`, // Endpoint for fetching table data  
  reduceBanquetSeats: `${API_URL}/reduce-banquet-seats`, // Endpoint for reducing banquet seat availability
  googleLogin: `${API_URL}/google-login`, // 
  // Add other endpoints here
};

// Export the mode variable
export { mode };
