import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EventTimeDetails = ({ formData, setFormData }) => {
    // Handle date change
    const handleDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            EventDate: date,
        }));
    };

    // Handle time fields change
    const handleTimeChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className="form-group">
            <div className="row gy-3">
                {/* Event Date */}
                <div className="col-lg-3 col-md-6 col-12">
                    <label className="form-label text-primary fw-bold" style={{ fontSize: '1rem' }}>
                        Event Date:
                    </label>
                    <DatePicker
                        selected={formData.EventDate}
                        onChange={handleDateChange}
                        className="form-control shadow-sm border-primary"
                        placeholderText="Select Date"
                        style={{ borderRadius: '8px' }}
                        dateFormat="yyyy-MM-dd"
                    />
                </div>

                {/* Start Time */}
                <div className="col-lg-3 col-md-6 col-12">
                    <label className="form-label text-success fw-bold" style={{ fontSize: '1rem' }}>
                        Start Time:
                    </label>
                    <input
                        type="time"
                        className="form-control shadow-sm border-success"
                        name="StartTime"
                        value={formData.StartTime || ""}
                        onChange={handleTimeChange}
                        style={{ borderRadius: '8px' }}
                    />
                </div>

                {/* End Time */}
                <div className="col-lg-3 col-md-6 col-12">
                    <label className="form-label text-warning fw-bold" style={{ fontSize: '1rem' }}>
                        End Time:
                    </label>
                    <input
                        type="time"
                        className="form-control shadow-sm border-warning"
                        name="EndTime"
                        value={formData.EndTime || ""}
                        onChange={handleTimeChange}
                        style={{ borderRadius: '8px' }}
                    />
                </div>

                {/* Doors Open Time */}
                <div className="col-lg-3 col-md-6 col-12">
                    <label className="form-label text-danger fw-bold" style={{ fontSize: '1rem' }}>
                        Doors Open Time:
                    </label>
                    <input
                        type="time"
                        className="form-control shadow-sm border-danger"
                        name="DoorsOpenTime"
                        value={formData.DoorsOpenTime || ""}
                        onChange={handleTimeChange}
                        style={{ borderRadius: '8px' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default EventTimeDetails;