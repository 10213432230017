import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';


      
const PrivacyPolicy = () => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={12}>
          <Card className="shadow-lg p-4">
            <Card.Body>
              <Card.Title className="text-center mb-4">Privacy Policy for <a href="https://eventtop.ca">https://eventtop.ca</a></Card.Title>
              
              <p className="text-center mt-0 mb-4 text-muted small">Last updated: December 29, 2024</p>
              
    {/* Introduction */}
    <div className="col-12 mb-4">
    <div className="card shadow-sm">
    <div className="card-body">
    <h5 className="card-title">1. Introduction</h5>
    <p className="card-text">
    Welcome to <a href="https://eventtop.ca">eventtop.ca</a> (the "Website"). EventTop ("we", "our", "us") is committed to protecting the privacy of its users. This Privacy Policy explains how we collect, use, and disclose your personal information in compliance with the Personal Information Protection and Electronic Documents Act (PIPEDA).
    </p>
    </div>
    </div>
    </div>
    
    
    {/* Information We Collect */}
    <div className="col-12 mb-4">
    <div className="card shadow-sm">
    <div className="card-body">
    <h5 className="card-title">2. Information We Collect</h5>
    <p className="card-text">
    We collect personal information to provide and improve our services. The types of information we collect include:
    </p>
    <ul>
    <li><strong>Identifying Information:</strong> Name, email address, phone number, and mailing address.</li>
    <li><strong>Account Information:</strong> Username, password, and account preferences.</li>
    <li><strong>Transaction Information:</strong> Purchase history and payment details.</li>
    <li><strong>Usage Data:</strong> Information about how you interact with our platform, including IP address, browser type, and pages visited.</li>
    </ul>
    </div>
    </div>
    </div>
                  
    {/* How We Use Your Information */}
    <div className="col-12 mb-4">
    <div className="card shadow-sm">
    <div className="card-body">
    <h5 className="card-title">3. How We Use Your Information</h5>
    <p className="card-text">Your personal information is used for the following purposes:</p>
    <ul>
    <li>To provide and personalize our services.</li>
    <li>To process transactions and send confirmations.</li>
    <li>To communicate with you, including responding to inquiries.</li>
    <li>To send promotional materials or service updates (with your consent).</li>
    <li>To improve our platform and address technical issues.</li>
    <li>To comply with legal obligations such as court orders or government regulations.</li>
    <li>To protect and defend our rights or property, including enforcing our terms of service.</li>
    <li>In connection with a merger, acquisition, or sale of assets, your personal information may be transferred as part of the business transaction.</li>
    </ul>
    </div>
    </div>
    </div>
    
    {/* Consent */}
    <div className="col-12 mb-4">
    <div className="card shadow-sm">
    <div className="card-body">
    <h5 className="card-title">4. Your Consent</h5>
    <p className="card-text">
    By using our services, you consent to the collection, use, and disclosure of your personal information as outlined in this policy. Where required, we will seek additional consent (e.g., for marketing or sharing with third parties). You may withdraw your consent at any time by contacting us at{' '}
    <a href="mailto:info@eventtop.ca">info@eventtop.ca</a>.
    </p>
    </div>
    </div>
    </div>
    
    {/* User Rights */}
    <div className="col-12 mb-4">
    <div className="card shadow-sm">
    <div className="card-body">
    <h5 className="card-title">5. Accessing and Updating Your Information</h5>
    <p className="card-text">
    You have the right to opt-out of receiving promotional communications from us at any time. You can do this by following the unsubscribe instructions provided in the email or contacting us directly. You may also have the right to request access to, correction of, or deletion of your personal information. Please contact us to exercise these rights. To make a request, please contact us at{' '}
    <a href="mailto:info@eventtop.ca">info@eventtop.ca</a>.
    </p>
    </div>
    </div>
    </div>
    
    {/* Security */}
    <div className="col-12 mb-4">
    <div className="card shadow-sm">
    <div className="card-body">
    <h5 className="card-title">6. Security</h5>
    <p className="card-text">
    We implement reasonable security measures to protect your personal information from unauthorized access, alteration, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security. We recommend taking steps to protect your own personal information, such as using strong passwords and keeping your software up to date.
    </p>
    </div>
    </div>
    </div>
              
                            
              <Card.Title className="mt-4">Third-Party Links</Card.Title>
              <p>Our Website may contain links to third-party websites. These sites are not operated by us, and we are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of any third-party sites you visit to understand how they collect and use your information.</p>
                                          
              <Card.Title className="mt-4">Changes to this Privacy Policy</Card.Title>
              <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on the Website. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
              
              <Card.Title className="mt-4">Contact Us</Card.Title>
              <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at <a href="mailto:eventtop.board@gmail.com">eventtop.board@gmail.com</a>. We value your privacy and will respond to your inquiry as soon as possible.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
  