import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const TicketPriceCalculator = () => {
  const [hasSeatMap, setHasSeatMap] = useState(false);
  const [paysFees, setPaysFees] = useState('ticketBuyer');
  const [isFree, setIsFree] = useState(false);
  const [soldTickets, setSoldTickets] = useState(0);
  const [ticketPrice, setTicketPrice] = useState(0);
  const navigate = useNavigate();

  const handleCreateEvent = () => {
    navigate("/registration-form");
  };

  const calculateFees = () => {
    if (isFree || soldTickets === 0 || ticketPrice === 0) {
      return { 
        serviceFee: 0, 
        paymentProcessingFee: 0, 
        totalFeesPerTicket: 0, 
        attendeesPay: 0, 
        payout: 0 
      };
    }
    
    const serviceFee = hasSeatMap
      ? 2.00 + 0.030 * ticketPrice
      : 0.65 + 0.0175 * ticketPrice;
    const paymentProcessingFee = 0.3 + 0.029 * ticketPrice;
    
    const totalFeesPerTicket =
      paysFees === 'ticketBuyer'
        ? serviceFee + paymentProcessingFee
        : serviceFee;
        
    const attendeesPay = soldTickets * (ticketPrice + (paysFees === 'ticketBuyer' ? totalFeesPerTicket : 0));
    const payout = soldTickets * (ticketPrice - (paysFees === 'organizer' ? totalFeesPerTicket : 0));
    
    return { serviceFee, paymentProcessingFee, totalFeesPerTicket, attendeesPay, payout };
  };
  
  const { serviceFee, paymentProcessingFee, totalFeesPerTicket, attendeesPay, payout } = calculateFees();
  
  return (
    <Container className="py-5">
      <h1 className="text-center mb-2" style={{ color: '#4CAF50', fontWeight: 'bold' }}>
        Plan Your Event Finances with Ease
      </h1>
      <h3 className="text-center mt-0" style={{ color: '#4CAF50', fontWeight: 'bold' }}>
      Event Calculator
      </h3>
      <Row className="align-items-stretch g-3">
        {/* Left Column: Event Details */}
        <Col md={6} xs={12}>
          <Card
            className="shadow border-0 h-100"
            style={{
              background: 'linear-gradient(135deg, #FFDDC1, #FFABAB)',
              color: '#333',
              borderRadius: '10px',
            }}
          >
            <Card.Body>
              <h4 className="mb-4" style={{ color: '#C8553D', fontWeight: '600' }}>
                Event Details
              </h4>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Does your event have a seat map?</Form.Label>
                  <div className="d-flex">
                    <Button
                      variant={hasSeatMap ? 'primary' : 'outline-primary'}
                      onClick={() => setHasSeatMap(true)}
                      className="me-2"
                    >
                      Yes
                    </Button>
                    <Button
                      variant={!hasSeatMap ? 'primary' : 'outline-primary'}
                      onClick={() => setHasSeatMap(false)}
                    >
                      No
                    </Button>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Who pays service fees?</Form.Label>
                  <p>
                    <small className="text-muted mt-0">
                      {hasSeatMap
                        ? '%3.00 + C$2.00 per ticket for non-free tickets'
                        : '%1.75 + C$0.65 per ticket for non-free tickets'}
                    </small>
                  </p>
                  <div className="d-flex">
                    <Button
                      variant={paysFees === 'ticketBuyer' ? 'primary' : 'outline-primary'}
                      onClick={() => setPaysFees('ticketBuyer')}
                      className="me-2"
                    >
                      Ticket Buyer
                    </Button>
                    <Button
                      variant={paysFees === 'organizer' ? 'primary' : 'outline-primary'}
                      onClick={() => setPaysFees('organizer')}
                    >
                      Organizer
                    </Button>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Is your event free?</Form.Label>
                  <div className="d-flex">
                    <Button
                      variant={isFree ? 'primary' : 'outline-primary'}
                      onClick={() => setIsFree(true)}
                      className="me-2"
                    >
                      Yes
                    </Button>
                    <Button
                      variant={!isFree ? 'primary' : 'outline-primary'}
                      onClick={() => setIsFree(false)}
                    >
                      No
                    </Button>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Number of tickets sold</Form.Label>
                  <Form.Control
                    type="number"
                    value={soldTickets === 0 && soldTickets !== '' ? '' : soldTickets}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSoldTickets(value === '' ? '' : Math.max(0, Number(value)));
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Ticket Price (CAD)</Form.Label>
                  <Form.Control
                    type="number"
                    value={ticketPrice === 0 && ticketPrice !== '' ? '' : ticketPrice}
                    onChange={(e) => {
                      const value = e.target.value;
                      setTicketPrice(value === '' ? '' : Math.max(0, Number(value)));
                    }}
                    disabled={isFree}
                  />
                </Form.Group>

              </Form>
            </Card.Body>
          </Card>
        </Col>

        {/* Right Column: Summary */}
        <Col md={6} xs={12}>
          <Card
            className="shadow border-0 h-100"
            style={{
              background: 'linear-gradient(135deg, #A7FFEB, #69EACB)',
              color: '#333',
              borderRadius: '10px',
            }}
          >
            <Card.Body className="d-flex flex-column">
              <h4 className="mb-4" style={{ color: '#008080', fontWeight: '600' }}>
                Summary
              </h4>
              <div className="d-flex flex-column gap-2">
                <p className="mb-1">
                  <strong>Service Fee Per Ticket:</strong>{' '}
                  <span className="text-primary fw-bold">
                    ${isFree ? 0 : serviceFee.toFixed(2)}
                  </span>
                </p>
                <p className="mb-0">
                  <strong>Payment Processing Fee Per Ticket:</strong>{' '}
                  <span className="text-primary fw-bold">
                    ${isFree ? 0 : paymentProcessingFee.toFixed(2)}
                  </span>
                </p>
                <small className="text-muted mt-0">
                  Note: Payment processing fees include 2.9% + $0.30 and are paid by attendees.
                </small>
              </div>

              <div className="text-center mt-3">
                <h3 className="text-orange">
                  <strong>Attendees Pays</strong>
                </h3>
                <h3 className="text-primary fw-bold">
                  ${isFree ? 0 : attendeesPay.toFixed(2)}
                </h3>
              </div>

              <div className="text-center mt-3">
                <h3 className="text-orange">
                  <strong>Your Estimated Payout</strong>
                </h3>
                <h3 className="text-primary fw-bold">
                  ${isFree ? 0 : payout.toFixed(2)}
                </h3>
              </div>

              <small className="text-muted mt-3">
                Complimentary tickets may incur charges based on the event’s service fee.
              </small>
              <div className="text-center mt-3">
                <Button
                  variant="success"
                  onClick={handleCreateEvent}
                  className="w-100 mt-3"
                >
                  Get Started for Free
                </Button>
              </div>
              <div className="text-center mt-3">
                <p>
                  Have questions? Contact our <a href="mailto:info@eventtop.ca">sales specialist</a>.
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TicketPriceCalculator;