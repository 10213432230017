// General addmission

import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { fetchWithAuth } from '../react-auth-app/auth';
import { API_ENDPOINTS } from '../config';
import { calculateConvenienceFee } from '../utils/convenienceFeeCalculator';
import { calculateProcessingFee } from '../utils/processingFeeCalculator';
import { calculateSalesTax } from '../utils/taxCalculator';
import { calculateTotal } from '../utils/totalCalculator';

const GeneralAdmissionPurchase = ({ event }) => {
  const navigate = useNavigate();
  const [ticketOptions, setTicketOptions] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [salesTax, setSalesTax] = useState(0);
  const [totalWithFees, setTotalWithFees] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [availabilityWarning, setAvailabilityWarning] = useState({});

  // Fetch ticket data callback
  const fetchTicketData = useCallback(async (eventId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.getEventTickets(eventId));
      if (response.ok) {
        const data = await response.json();
        if (data && data.tickets) {
          setTicketOptions(data.tickets);
          console.log('data:',data)
        }
      } else {
        console.error(`Failed to fetch tickets. Status code: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching event tickets:', error);
    }
    
  }, []);

  // Fetch ticket data when the component loads
  useEffect(() => {
    if (event && event.EventID) {
      fetchTicketData(event.EventID);
    } else {
      console.warn('Event or event.EventID is missing.');
    }
  }, [event, fetchTicketData]);

  // Calculate the total amount, fees, and total with tax
  // Calculate the total amount, fees, and total with tax
  // Calculate the total amount, fees, and total with tax
  useEffect(() => {
    // Calculate the subtotal for selected tickets
    const subtotal = Object.values(selectedTickets).reduce(
      (acc, { price, quantity }) => acc + price * quantity,
      0
    );
    
    // Extract total quantity from selectedTickets
    const totalQuantity = Object.values(selectedTickets).reduce(
      (acc, { quantity }) => acc + quantity,
      0
    );
    
    const eventSeatMap = event.SeatMap
    
    console.log('eventSeatMap',eventSeatMap)
    
    // Calculate convenience and processing fees
    const calculatedConvenienceFee = calculateConvenienceFee(subtotal, totalQuantity, 'generalAdmission');
    const calculatedProcessingFee = calculateProcessingFee(subtotal + calculatedConvenienceFee);
    
    // Calculate sales tax
    const calculatedSalesTax = calculateSalesTax(calculatedConvenienceFee);
    
    // Determine if any ticket absorbs the service fee
    const anyTicketAbsorbsFee = Object.keys(selectedTickets).some(ticketId => {
      const selectedTicket = ticketOptions.find(ticket => ticket.TicketID === parseInt(ticketId, 10));
      return selectedTicket && selectedTicket.AbsorbServiceFee === 1;
    });
    
    // Final total calculation based on fee absorption
    const totalWithTax = anyTicketAbsorbsFee
    ? subtotal + calculatedSalesTax // Exclude convenience and processing fees
    : subtotal + calculatedConvenienceFee + calculatedProcessingFee + calculatedSalesTax; // Include all fees
    
    // Update state
    setTotalAmount(subtotal);
    setConvenienceFee(calculatedConvenienceFee); // Always calculated
    setProcessingFee(calculatedProcessingFee);   // Always calculated
    setSalesTax(calculatedSalesTax);
    setTotalWithFees(totalWithTax);
  }, [selectedTickets, ticketOptions]);


  // updated code
  const handleTicketChange = (ticketId, price, quantity) => {
    const selectedTicket = ticketOptions.find(ticket => ticket.TicketID === ticketId);
    const available = selectedTicket.Availability;
    
    if (quantity > available) {
      setAvailabilityWarning((prev) => ({
        ...prev,
        [ticketId]: `Only ${available} tickets are available. Please reduce your selection.`,
      }));
      console.log(`Invalid quantity: ${quantity} exceeds availability: ${available}`);

      return; // Prevent state update for invalid quantities
    } else {
      setAvailabilityWarning((prev) => ({
        ...prev,
        [ticketId]: '',
      }));
    }
    
    setSelectedTickets((prevTickets) => ({
      ...prevTickets,
      [ticketId]: {
        price,
        quantity: parseInt(quantity, 10),
        ticketType: selectedTicket.TicketType,
        Notes: selectedTicket.Notes,
        details: selectedTicket.TicketDetails
      },
    }));
  };

  const handlePurchase = () => {
    // Validate all selected tickets against availability
    const isValid = Object.keys(selectedTickets).every(ticketId => {
      const selectedTicket = ticketOptions.find(ticket => ticket.TicketID === parseInt(ticketId, 10));
      return (
        selectedTicket &&
        selectedTickets[ticketId].quantity > 0 &&
        selectedTickets[ticketId].quantity <= selectedTicket.Availability
      );
    });
    
    if (!isValid) {
      setShowAlert(true); // Show alert for invalid quantities
      console.log('Invalid selection: Exceeded availability or invalid quantity.');
      return;
    }
    
    if (Object.keys(selectedTickets).length === 0 || totalAmount === 0) {
      setShowAlert(true); // Show alert if no tickets are selected
      console.log('No tickets selected or total amount is zero.');
      return;
    }
    
    // Proceed with purchase
    navigate('/Checkout', {
      state: { event, selectionType: 'generalAdmission', selectedTickets, totalWithFees, totalAmount },
    });
  };
  
  //console.log('fetchTicketData:',fetchTicketData)
  
  return (
    <Container className="mt-3">
      <h5 className="text-left MB-3">Please select at least one ticket type to proceed.</h5>
      {showAlert && <Alert variant="danger">Please select at least one ticket type to proceed.</Alert>}

      {/* Render ticket details */}
      {ticketOptions.length > 0 && (
        <div className="mb-3 p-3 shadow-lg">
        <ul className="list-group list-group-flush" style={{ backgroundColor: "#e0e4e5", borderRadius: "12px" }}>
        {ticketOptions.map((ticket) => (
          <li key={ticket.TicketID} className="list-group-item" style={{ backgroundColor: "#f5f5f5", borderRadius: "10px" }}>
          <strong>{ticket.TicketType}</strong> - ${ticket.Price}
          {/* Ticket notes on the second line */}
          {ticket.TicketDetails && (
            <div className="text-info mt-0">- {ticket.TicketDetails}</div>
          )}
          
          {ticket.Notes && (
            <div className="text-info mt-0">- {ticket.Notes}</div>
          )}
          
          {ticket.Availability === 0 && (
            <span
            className="badge badge-danger"
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              backgroundColor: '#ff4d4d',
              color: '#fff',
              fontWeight: 'bold',
              fontSize: '0.9rem',
              borderRadius: '8px',
              padding: '5px 10px',
            }}
            >
            Sold Out
            </span>
          )}
          </li>
        ))}
        
        </ul>
        </div>
      )}
    
    <Form>
    {ticketOptions.map((ticket) => (
      <Form.Group key={ticket.TicketID} controlId={`ticket-${ticket.TicketID}`}>
      <Form.Label>{ticket.TicketType} - ${ticket.Price} </Form.Label>
      <Form.Control
      type="number"
      inputMode="numeric" // Ensures numeric keyboard on mobile
      min="0"
      max={ticket.Availability}
      placeholder="Enter quantity"
      disabled={ticket.Availability === 0}
      onChange={(e) => handleTicketChange(ticket.TicketID, ticket.Price, e.target.value || 0)}
      />
      {availabilityWarning[ticket.TicketID] && (
        <small className="text-danger">{availabilityWarning[ticket.TicketID]}</small>
      )}
      </Form.Group>
    ))}
    
    <div className="mt-3">
    <p className="mb-1"><strong>Subtotal:</strong> ${totalAmount > 0 ? totalAmount.toFixed(2) : '0.00'}</p>
    
    {/* Show fees and taxes only if a ticket quantity has been entered */}
    {totalAmount > 0 && (
      <>
      {!Object.keys(selectedTickets).some(ticketId => {
        const selectedTicket = ticketOptions.find(ticket => ticket.TicketID === parseInt(ticketId, 10));
        return selectedTicket && selectedTicket.AbsorbServiceFee === 1;
      }) && (
        <>
        <p className="mb-1"><strong>Convenience Fee:</strong> ${convenienceFee > 0 ? convenienceFee.toFixed(2) : '0.00'}</p>
        <p className="mb-1"><strong>Processing Fee:</strong> ${processingFee > 0 ? processingFee.toFixed(2) : '0.00'}</p>
        </>
      )}
      
      <p className="mb-1"><strong>Sales Tax (13% HST):</strong> ${salesTax > 0 ? salesTax.toFixed(2) : '0.00'}</p>
      <p className="mb-1"><strong>Total (Tax & Fees included):</strong> ${totalWithFees > 0 ? totalWithFees.toFixed(2) : '0.00'}</p>
      </>
    )}
    
    {totalAmount === 0 && (
      <small className="d-block">Please select a ticket to proceed with fees and taxes calculation.</small>
    )}
    </div>
    
    <Row className="mt-3">
    <Button 
    variant="primary" 
    onClick={handlePurchase} 
    disabled={totalAmount === 0 || Object.keys(availabilityWarning).some(key => availabilityWarning[key])}>
    Proceed to Purchase
    </Button>
    </Row>
    </Form>
    
    </Container>
  );
};

export default GeneralAdmissionPurchase;