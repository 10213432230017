// taxCalculator.js

// Provincial and Territorial Sales Tax Rates (2025)
const TAX_RATES = {
  AB: 0.05, // Alberta: GST only
  BC: 0.12, // British Columbia: GST + PST
  MB: 0.12, // Manitoba: GST + PST
  NB: 0.15, // New Brunswick: HST
  NL: 0.15, // Newfoundland and Labrador: HST
  NS: 0.15, // Nova Scotia: HST
  NT: 0.05, // Northwest Territories: GST only
  NU: 0.05, // Nunavut: GST only
  ON: 0.13, // Ontario: HST
  PE: 0.15, // Prince Edward Island: HST
  QC: 0.14975, // Quebec: GST + QST
  SK: 0.11, // Saskatchewan: GST + PST
  YT: 0.05, // Yukon: GST only
};

// Routine to calculate sales tax
export const calculateSalesTax = (amount, province = 'QC') => {
  if (isNaN(amount) || amount < 0) {
    return 0; // Return 0 for invalid amounts
  }

  const taxRate = TAX_RATES[province] ?? 0.13; // Default to Ontario's rate if province not found
  return amount * taxRate;
};