import React, { useState } from "react";

const VenueInfo = ({ formData, setFormData }) => {
  const [loading, setLoading] = useState(false);

  const generateVenueDetails = async () => {
    if (!formData.VenueName.trim() || !formData.VenueAddress.trim()) {
      alert("Please enter both the venue name and address.");
      return;
    }

    setLoading(true);
    try {
      console.log("Generating venue details...");

      const endpoint = "https://api.openai.com/v1/chat/completions";
      const headers = {
        Authorization: `Bearer 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`, // Replace with your OpenAI API key
        "Content-Type": "application/json",
      };

      const body = JSON.stringify({
        model: "gpt-3.5-turbo", // Use "gpt-4" for better results if available
        messages: [
          {
            role: "system",
            content: "You are an assistant that generates detailed venue descriptions.",
          },
          {
            role: "user",
            content: `Provide detailed venue information for the following venue, make it one whole paragraph:
            Name: ${formData.VenueName}
            Address: ${formData.VenueAddress}`,
          },
        ],
        temperature: 0.8,
        max_tokens: 400, // Adjust token limit as needed
        top_p: 0.9,
      });

      console.log("Request Body:", body);

      const response = await fetch(endpoint, {
        method: "POST",
        headers,
        body,
      });

      console.log("Response Status:", response.status);
      const data = await response.json();
      console.log("Response Data:", data);

      if (response.status !== 200 || data.error) {
        console.error("API Error:", data.error || data);
        alert("API Error: " + (data.error?.message || "Unexpected error occurred."));
      } else if (data.choices && data.choices[0]?.message?.content) {
        const generatedText = data.choices[0].message.content.trim();
        console.log("Generated Venue Details:", generatedText);
        setFormData((prevData) => ({
          ...prevData,
          VenueDetails: generatedText,
        }));
      } else {
        console.warn("Unexpected response structure:", data);
        alert("Unexpected response structure. Unable to generate venue details.");
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      alert("An unexpected error occurred: " + error.message);
    } finally {
      setLoading(false);
      console.log("Venue details generation process complete.");
    }
  };

  return (
    <div className="form-group mt-4">
      <h5 className="text-primary fw-bold mb-3">Venue Information</h5>

      {/* Venue Name */}
      <label className="fw-bold">Venue Name:</label>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control shadow-sm border-primary"
          placeholder="Enter Venue Name"
          value={formData.VenueName}
          onChange={(e) =>
            setFormData((prevData) => ({
              ...prevData,
              VenueName: e.target.value,
            }))
          }
          style={{ borderRadius: "8px 0 0 8px" }}
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={generateVenueDetails}
          disabled={loading}
        >
          {loading ? "Generating..." : "Generate"}
        </button>
      </div>

      {/* Venue Address */}
      <label className="fw-bold">Venue Address:</label>
      <input
        type="text"
        className="form-control shadow-sm border-primary mb-3"
        placeholder="Enter Venue Address"
        value={formData.VenueAddress}
        onChange={(e) =>
          setFormData((prevData) => ({
            ...prevData,
            VenueAddress: e.target.value,
          }))
        }
        style={{ borderRadius: "8px" }}
      />

      {/* Venue Details */}
      <label className="fw-bold">Venue Details:</label>
      <textarea
        className="form-control shadow-sm border-primary"
        placeholder="Generated venue details will appear here..."
        value={formData.VenueDetails}
        onChange={(e) =>
          setFormData((prevData) => ({
            ...prevData,
            VenueDetails: e.target.value,
          }))
        }
        style={{ height: "200px", borderRadius: "8px" }}
      ></textarea>
      <small className="form-text text-muted mt-1">
        Example: "The Grand Concert Hall is a state-of-the-art venue located in the heart of downtown, featuring a seating capacity of 2,500, advanced acoustics, and a spacious lobby for networking."
      </small>
    </div>
  );
};

export default VenueInfo;